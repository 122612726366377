import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import Section from '../components/Section';
import Layout from '../components/Layout';
import ProjectHeader from '../components/ProjectHeader';
import Footer from '../components/Footer';

const Title = styled(Text)`
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const GrandTitle = styled(Text)`
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const PrivacyContent = () => (
  <Section.Container id="Privacy">
    <StaticQuery
      query={graphql`
        query privacy {
          __typename
          contentfulPrivacy {
            betreiberDe {
              betreiberDe
            }
            betreiberEn {
              betreiberEn
            }
            informationenDe {
              informationenDe
            }
            informationenEn {
              informationenEn
            }
            umgangMitDatenDe {
              umgangMitDatenDe
            }
            umgangMitDatenEn {
              umgangMitDatenEn
            }
            kontaktDe {
              kontaktDe
            }
            kontaktEn {
              kontaktEn
            }
            sslVerschlsselungDe {
              sslVerschlsselungDe
            }
            sslVerschlsselungEn {
              sslVerschlsselungEn
            }
          }
        }
      `}
      render={({ contentfulPrivacy }) => {
        return (
          <Flex justifyContent="center" flexWrap="wrap">
            <Box width={[1, 1, 4 / 8]} px={[1, 2, 4]}>
              <GrandTitle>Datenschutz</GrandTitle>
              <Text my={1}>{contentfulPrivacy.betreiberDe.betreiberDe}</Text>
              <Text my={1}>
                {contentfulPrivacy.informationenDe.informationenDe}
              </Text>
              <Text my={1}>
                {contentfulPrivacy.umgangMitDatenDe.umgangMitDatenDe}
              </Text>

              <Title>Kontakt</Title>
              <Text>{contentfulPrivacy.kontaktDe.kontaktDe}</Text>

              <Title>SSL-Verschlüsselung</Title>
              <Text>
                {contentfulPrivacy.sslVerschlsselungDe.sslVerschlsselungDe}
              </Text>
            </Box>

            <Box width={[1, 1, 4 / 8]} px={[1, 2, 4]}>
              <GrandTitle>Declaration on Data Privacy</GrandTitle>
              <Text my={1}>{contentfulPrivacy.betreiberEn.betreiberEn}</Text>
              <Text my={1}>
                {contentfulPrivacy.informationenEn.informationenEn}
              </Text>
              <Text my={1}>
                {contentfulPrivacy.umgangMitDatenEn.umgangMitDatenEn}
              </Text>

              <Title>Contact</Title>
              <Text>{contentfulPrivacy.kontaktEn.kontaktEn}</Text>

              <Title>SSL-Security</Title>
              <Text>
                {contentfulPrivacy.sslVerschlsselungEn.sslVerschlsselungEn}
              </Text>
            </Box>
          </Flex>
        );
      }}
    />
  </Section.Container>
);

const IndexPage = () => (
  <Layout>
    <ProjectHeader />
    <PrivacyContent />
    <Footer />
  </Layout>
);

export default IndexPage;
